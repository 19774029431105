import React from 'react';



const AbstractsViewer = () => {
  return (
    <div className="App">
      <header style={{
        backgroundColor: '#3f51b5',
        color: 'white',
        padding: '20px',
        marginBottom: '20px',
        textAlign: 'center'
      }}>
        <h1 style={{
          margin: 0,
          fontSize: '24px',
          marginBottom: '10px'
        }}>ESMO Abstracts Visualizer</h1>
      </header>
      
      <div style={{
        maxWidth: '600px',
        margin: '0 auto',
        padding: '20px',
        textAlign: 'center',
        lineHeight: '1.6'
      }}>
        <p>
          The ESMO 2024 Abstracts Visualizer has been taken down. 
          If you would like to access or use the data, 
          please reach out to us at info@navisbio.com.
        </p>
        <p>
          Thank you for your interest in our tool.
        </p>
      </div>
      
    </div>
  );
};

export default AbstractsViewer;