import React, { useState, useEffect } from 'react';
import { ChevronDown, Mail, MapPin } from 'lucide-react';
import TabContent from './components/TabContent';
import { useSearchParams, useLocation } from 'react-router-dom';

const TAGLINE = "AI for translational medicine";

const FullPageSection = ({ children, className }) => (
  <section className={`full-page-section bg-white py-12 ${className}`}>
    <div className="max-w-6xl mx-auto px-4 w-full">
      {children}
    </div>
  </section>
);


const HeroSection = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToContent = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth'
    });
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-peach">
      <div className="relative text-center">
        <div className="relative z-10">
          <h1 className="text-6xl font-bold text-burgundy mb-6 transition-all duration-500 ease-out"
              style={{ transform: `translateY(-${scrollPosition * 0.2}px)` }}>
            Navis Bio
          </h1>
          <p className="text-2xl text-white mt-4 max-w-2xl mx-auto transition-all duration-500 ease-out"
             style={{ transform: `translateY(-${scrollPosition * 0.1}px)` }}>
            {TAGLINE}
          </p>
          <button onClick={scrollToContent} className="mt-12 text-burgundy hover:text-[#92CBEA] transition-colors duration-300">
            <ChevronDown size={32} />
          </button>
        </div>
      </div>
    </div>
  );
};

const FooterSection = () => {
  return (
    <footer className="bg-beige py-16">
      <div className="max-w-6xl mx-auto px-4">
        <div className="flex justify-between items-start">
          <div>
            <h2 className="text-2xl font-bold text-burgundy">Navis Bio</h2>
            <p className="text-black">{TAGLINE}</p>
          </div>
          <div>
            <h3 className="text-2xl font-bold text-burgundy">Contact Us</h3>
            <div className="flex items-center mt-2 text-black">
              <Mail className="mr-2" />
              <a href="mailto:info@navis-bio.com" className="hover:underline">
                info@navis-bio.com
              </a>
            </div>
            <div className="flex items-center text-black">
              <MapPin size={20} className="mr-2" />
              <span>Delaware, United States<br />Zurich, Switzerland</span>
            </div>
          </div>
        </div>
        <div className="mt-16 pt-8 border-t border-white/30 text-center text-black">
          <p>© 2024 Navis Bio Inc., All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};


const NavisBioLanding = () => {
  const [activeTab, setActiveTab] = useState('product');
  const location = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    // Handle tab from URL parameter
    const tabParam = searchParams.get('tab');
    if (tabParam) {
      setActiveTab(tabParam);
    }

    // Handle scroll to hash from state
    if (location.state?.scrollToId) {
      setTimeout(() => {
        const element = document.getElementById(location.state.scrollToId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    }
  }, [location.state, searchParams]);

  return (
    <div className="font-sans text-dark-blue">
      <HeroSection />
      <FullPageSection>
        <TabContent activeTab={activeTab} setActiveTab={setActiveTab} />
      </FullPageSection>
      <FooterSection />
    </div>
  );
};

export default NavisBioLanding;