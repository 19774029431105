import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import ErrorBoundary from './components/ErrorBoundary';
import NavisBioLanding from './NavisBioLanding';
import AbstractsViewer from './abstractsViewer/abstractsViewer';

// Create a wrapper component to handle the hash navigation
const HashRedirect = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      // If there's a hash, redirect to home with the blog tab active
      navigate('/?tab=blog', { 
        state: { scrollToId: location.hash.slice(1) },
        replace: true 
      });
    }
  }, [location.hash, navigate]);

  return null;
};

function App() {
  return (
    <Router>
      <ErrorBoundary>
        <HashRedirect />
        <div className="App">
          <Routes>
            <Route path="/" element={<NavisBioLanding />} />
            <Route path="/abstracts_viewer" element={<AbstractsViewer />} />
          </Routes>
        </div>
      </ErrorBoundary>
    </Router>
  );
}

export default App;