import React from 'react';
import trialLandscape from '../images/trial_landscape.png';
import '../../../styles/blog.css';

const Content = () => (
  <div>
    <p>The Annual Meeting and Exposition of the American Society for Hematology (ASH), 
       the most important conference in hematology, is around the corner.</p>
    
    <p>We released a <a href="http://conferencelandscape.navis-bio.com">dashboard</a> that 
       provides insights on compounds in pre-clinical or clinical development reported on by 
       industry sponsors.</p>
  
    
    <div className="image-container">
      <img 
        src={trialLandscape} 
        alt="Target landscape for AML"
        className="blog-image"
      />
      <p className="image-caption">
        Target landscape for AML at ASH 2024 showing the 8 most frequent targets/target combinations by trial phase. Details can be obtained by clicking on the target or by looking them up in the 'Table' tab.
      </p>
    </div>
    
    <p>Our AI agent framework categorizes abstracts submitted to conferences and annotates 
       information on the drug targets and modality from literature.</p>
    
    <p>Of note, this demo only provides information on the compounds and trials that were 
       reported at the conference. Our proprietary database combines this information with 
       data on the general trial landscape, past drug approvals, and current standard of care. 
       Feel free to book a meeting to learn more.</p>
  </div>
);

export const post = {
  title: "Rapid insights into targets and compounds presented at ASH 2024",
  date: "2024-12-05",
  Content
}; 