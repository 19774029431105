import React from 'react';

const Content = () => (
  <div>
    <p>We are busy building. Stay tuned for updates in the coming weeks!</p>
  </div>
);

export const post = {
  title: "Building AI for translational medicine",
  date: "2024-12-01",
  Content
}; 