import React from 'react';
import trialTermination from '../images/MCP_CTGOV_TrialTermination.png';
import '../../../styles/blog.css';

const Content = () => (
  <div>
    <p>The Aggregate Analysis of ClinicalTrials.gov (AACT) Database is a fantastic resource which stores all information about registered studies on ClinicalTrials.gov and is updated daily. 
       However, having to interact a SQL database with 68 tables and 100s of columns can be intimidating for 
       many users and in our experience this was often a reason not to use it.</p>

    <p>To enable easy access to this wealth of information, we built an interface with Anthropic's Claude 
       that allows the user to interactively interrogate the data and build a dashboard of metrics on all 
       questions regarding clinical trials.</p>

    <div className="image-container">
      <img 
        src={trialTermination}
        alt="Trial termination analysis for NSCLC PD-L1 positive patients vs other oncology indications"
        className="blog-image"
      />
      <p className="image-caption">
        Analysis whether trials looking for NSCLC PD-L1 positive patients have higher termination rates due to failed recruitment efforts than other oncology indications
      </p>
    </div>

    <h2>What is the Model Context Protocol?</h2>
    <p>The Model Context Protocol (MCP) is a standardized way to connect AI language models with external 
       information and tools. It currently only works with the desktop version of Anthropic's Claude and 
       still has a rather complex setup, but we expect the ease of use to evolve rapidly.</p>

    <p>The code is open-source and available on <a href="https://github.com/navisbio/ctgov_MCP" 
       target="_blank" rel="noopener noreferrer">https://github.com/navisbio/ctgov_MCP</a>. It also 
       provides instructions on setting it up. Over the coming weeks we aim to release MCP integrations 
       for other public datasets (OpenFDA, Chembl, OpenTargets,…).</p>

    <h2>Some caveats</h2>
    <ul>
      <li>MCP can only be used with the desktop version of Claude which is still in beta - we expect this to change in the coming months.</li>
      <li>You need to buy a Claude professional plan to use the MCP.</li>
      <li>The model still sometimes makes up numbers. Consistently asking it whether the numbers are based on the execution of a query solved this problem, but adds an extra user prompt (which will be automatically asked by the system in the future)</li>
    </ul>

    <h2>What's next?</h2>
    <p>We know that setting up a tool by manually changing configuration files in a software that is still in beta-release is not 
       to everyone's taste and that the need to buy a Claude subscription can be a blocker too. For this reason, and to enable more customization and guardrails, we are 
       working on our own frontend. This will also provide access to additional data curated by our AI agents, e.g. on the drug targets associated with the trials, or the current standard of care). 

       
       If this sounds interesting to you, we are happy to hear from you. (You can also directly 
       <a href="https://outlook.office.com/bookwithme/user/fe1d3db7da8649e993f9e39b0de9542f@navis-bio.com/meetingtype/tYuG2HHsMkehHgvsP0JJSg2?anonymous&ep=mcard" target="_blank" rel="noopener noreferrer">book 
       a meeting with us here</a>.)</p>
  </div>
);

export const post = {
  title: "Interrogating ClinicalTrials.gov with Claude",
  date: "2024-12-08",
  Content
}; 